<template>
  <modal
    transition="nice-modal-fade"
    width="680"
    :adaptive="true"
    :reset="true"
    height="auto"
    :name="modalName"
    @before-open="beforeOpen"
  >
    <div class="modal--wrapper">
      <div class="back--wrapper" v-if="$mq === 'xs'">
        <div class="back--button" @click="close()">
          <img src="@/assets/img/icons/back-2.svg" />
        </div>
        {{ $t('booking.modal.acceptBook.title') }}
      </div>
      <SectionLoader :show="isLoading" />
      <section class="modal--container accept--survey-container" v-show="step === 1">
        <div class="title--text">
          {{ $t('booking.modal.acceptBook.message') }}
        </div>
        <div class="date--time" v-if="modalData">
          <div class="single--row">
            <div class="left--col">{{ $t('general.startFrom') }}</div>
            <div class="right--col">{{ startDate }}</div>
          </div>
          <div class="single--row">
            <div class="left--col">{{ $t('general.until') }}</div>
            <div class="right--col">{{ endDate }}</div>
          </div>
          <div class="single--row">
            <div class="left--col">{{ $t('general.rentDuration') }}</div>
            <div class="right--col">{{ modalData.duration }}</div>
          </div>
          <div class="single--row">
            <div class="left--col">{{ $t('general.price') }}</div>
            <div class="right--col">{{ modalData.finalPrice }}</div>
          </div>
        </div>
        <div class="form--group" :class="{ 'has-error': validation.hasError('newPrice') }">
          <div class="left--col">
            <span>{{ $t('general.finalPrice') }}</span>
          </div>
          <div class="right--col">
            <div class="input--group">
              <div class="append--left">
                S$
              </div>
              <cleave
                class="basic--input price--input"
                type="text"
                name="new_price"
                v-model="newPrice"
                :options="cleaveOption"
                ref="price"
              />
            </div>
            <span class="val-error" v-if="validation.hasError('newPrice')">{{
              validation.firstError('newPrice')
            }}</span>
          </div>
        </div>
        <hr class="separator" />
        <div class="d-flex justify-content-between" :class="{ 'flex-wrap': $mq === 'xs' }">
          <div class="btn btn-primary--outline main--btn" @click="prevStep">
            {{ $t('addListing.btn.back') }}
          </div>
          <div
            v-if="modalData"
            v-show="step !== 3"
            class="btn btn-primary main--btn"
            @click="nextStep"
          >
            {{
              step == 1 && modalData.allow_add_clause
                ? $t('booking.modal.acceptBook.next')
                : $t('booking.modal.acceptBook.acceptBtn')
            }}
          </div>
        </div>
      </section>
      <section class="modal--container accept--survey-container" v-show="step === 2">
        <div class="col-12">
          <div class="row align-items-center desc">
            <div class="col">
              {{ $t('booking.useDigitalAgreement') }}
            </div>
            <div class="col-auto">
              <div class="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="use-digital-agreement"
                  name="use-digital-agreement"
                  v-model="useDigitalAgreement"
                />
                <label class="custom-control-label" for="use-digital-agreement"></label>
              </div>
            </div>
          </div>
          <div class="alert alert-warning" role="alert" v-if="useDigitalAgreement">
            {{ $t('booking.addClauseHint') }}
          </div>
          <div class="alert alert-warning" role="alert" v-else>
            {{ $t('booking.notUsingDigitalAgreement') }}
          </div>
          <div class="desc" v-if="useDigitalAgreement">
            {{ $t('booking.addAdditionalClauseBelow') }}
          </div>
        </div>
        <div class="col-12" id="popOverClauses" v-if="useDigitalAgreement">
          <div class="clause--wrapper">
            <clause
              v-for="(clause, index) in clauses"
              :key="index"
              :index="index"
              :clause="clause"
              ref="clauseField"
              :clauseLength="clauses.length"
              commitString="v2/booking/acceptBookingRequest"
            ></clause>
          </div>
          <div class="col-12 p-0">
            <button
              :disabled="clauses.length >= 10"
              @click="addClause"
              class="btn btn-primary btn-block"
              type="button"
              id="clauseBtn"
            >
              {{ $t('booking.addClause') }}
            </button>
          </div>
          <hr />

          <div class="col-12 p-0">
            <button
              @click="openPreview"
              class="btn btn--green btn-block"
              type="button"
              id="openPreviewBtn"
            >
              {{ $t('booking.openPreview') }}
            </button>
          </div>
        </div>
        <div class="col-12" v-if="!customAgreementData">
          <div class="row align-items-center desc mb-0">
            <div class="col">
              {{ $t('booking.useOwnAgreement') }}
            </div>
            <div class="col-auto">
              <div class="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="use-own-agreement"
                  name="use-own-agreement"
                  v-model="useOwnAgreement"
                />
                <label class="custom-control-label" for="use-own-agreement"></label>
              </div>
            </div>
          </div>

          <div class="mt-2" v-if="useOwnAgreement">
            <div class="d-flex align-items-center" :class="{ 'flex-wrap': $mq === 'xs' }">
              <div class="col" v-if="customAgreement && customAgreement.file">
                {{ customAgreement.file.name }}
              </div>
              <img
                src="@/assets/img/icons/trash.svg"
                @click="removeAgreement"
                v-if="customAgreement && customAgreement.file"
                class="trash--button col-auto"
              />
            </div>
            <button type="button" class="btn btn-primary mx-auto mb-2" @click="uploadAgreement">
              {{ $t('booking.uploadAgreement') }}
            </button>
            <vue-dropzone
              v-show="false"
              ref="agreementDropzone"
              id="dropzone"
              :options="dropzoneOptions"
              @vdropzone-sending="sending"
              @vdropzone-success="success"
            />
          </div>

          <div class="alert alert-warning mt-2" role="alert" v-if="useOwnAgreement">
            *{{ $t('booking.uploadAgreementTerms') }}
          </div>
        </div>

        <hr class="separator" />
        <div class="d-flex justify-content-between" :class="{ 'flex-wrap': $mq === 'xs' }">
          <div class="btn btn-primary--outline main--btn" @click="prevStep">
            {{ $t('general.btn.back') }}
          </div>
          <div
            v-if="modalData"
            v-show="step !== 3"
            class="btn btn-primary main--btn"
            @click="nextStep"
          >
            {{
              step == 1 && modalData.allow_add_clause
                ? $t('booking.modal.acceptBook.next')
                : $t('booking.modal.acceptBook.acceptBtn')
            }}
          </div>
        </div>
      </section>

      <section class="modal--container accept--survey-container" v-show="step === 3">
        <div class="title--text">{{ $t('booking.digitalAgreementPreview') }}</div>
        <hr />
        <div v-html="digitalSignPreview" class="preview--wrapper"></div>
        <hr class="separator" />
        <div class="d-flex justify-content-between" :class="{ 'flex-wrap': $mq === 'xs' }">
          <div class="btn btn-primary--outline main--btn" @click="prevStep">
            {{ $t('general.btn.back') }}
          </div>
        </div>
      </section>
      <button type="button" class="close" @click="close" v-if="$mq !== 'xs'">
        <img src="@/assets/img/icons/close-1.svg" />
      </button>
    </div>
  </modal>
</template>

<script>
import ModalMixin from '../mixins/index';
import HelperMixin from '@/mixins/helpers';
import { Validator } from 'simple-vue-validator';
import { mapState } from 'vuex';
import Cleave from 'vue-cleave-component';
const Clause = () => import('./clause-field');
const SectionLoader = () => import('@/components/content-loading/section-loading');
import vue2Dropzone from 'nuxt-dropzone';

export default {
  name: 'booking-request-accept-modal',
  mixins: [ModalMixin, HelperMixin],
  components: { Cleave, SectionLoader, Clause, vueDropzone: vue2Dropzone },
  data() {
    return {
      cleaveOption: {
        prefix: '',
        numeral: true,
        numeralPositiveOnly: true,
        noImmediatePrefix: true,
        rawValueTrimPrefix: true,
        numeralDecimalMark: ',',
        delimiter: '.',
        numeralIntegerScale: 16,
        numeralDecimalScale: 0,
      },
      modalName: 'booking-request-accept-modal',
      modalData: null,
      startDate: '',
      endDate: '',
      actionType: 'BOOKING_REQUEST',
      step: 1,
      digitalSignPreview: '',
      // useDigitalAgreement: true
      dropzoneOptions: {
        url: `${this.$store.state.global.apiUrl}/api/v2/file_content/upload_pdf`,
        maxFilesize: 10,
        acceptedFiles: '.pdf',
        paramName: 'file',
      },
    };
  },
  validators: {
    newPrice(value) {
      return Validator.value(value).required(this.$i18n.t('errors.addListing.rentPrice.required'));
    },
  },
  computed: {
    ...mapState({
      clauses: state => state.v2.booking.acceptBookingRequest.clauses,
      customAgreementData: state => state.v2.booking.detail.customAgreementData,
    }),
    isLoading: {
      get() {
        return this.$store.state.v2.booking.acceptBookingRequest.isLoading;
      },
      set(val) {
        this.$store.commit('v2/booking/acceptBookingRequest/SET_IS_LOADING', val);
      },
    },
    newPrice: {
      get() {
        return this.$store.state.v2.booking.acceptBookingRequest.newPrice;
      },
      set(val) {
        this.$store.commit('v2/booking/acceptBookingRequest/SET_NEW_PRICE', val);
      },
    },
    useDigitalAgreement: {
      get() {
        return this.$store.state.v2.booking.acceptBookingRequest.useDigitalAgreement;
      },
      set(val) {
        this.$store.commit('v2/booking/acceptBookingRequest/SET_USE_DIGITAL_AGREEMENT', val);
      },
    },
    useOwnAgreement: {
      get() {
        return this.$store.state.v2.booking.acceptBookingRequest.useOwnAgreement;
      },
      set(val) {
        this.$store.commit('v2/booking/acceptBookingRequest/SET_USE_OWN_AGREEMENT', val);
      },
    },
    customAgreement: {
      get() {
        return this.$store.state.v2.booking.acceptBookingRequest.customAgreement;
      },
      set(val) {
        this.$store.commit('v2/booking/acceptBookingRequest/SET_CUSTOM_AGREEMENT', val);
      },
    },
  },

  methods: {
    async beforeOpen(event) {
      this._closed();
      this.step = 1;
      this.$store.commit('v2/booking/acceptBookingRequest/RESTORE_INITIAL_STATE');
      this._applicationUuid = event.params.bookingRequestHeaderUuid;
      this.$store.commit(
        'v2/booking/acceptBookingRequest/SET_BOOKING_REQUEST_UUID',
        event.params.bookingRequestHeaderUuid,
      );
      console.log('weiihh');
      await this._fetchApplicationData();
      this.newPrice = this.modalData.price;
      // Split between start date and end date from move date string.
      if (this.modalData && this.modalData.moveDate) {
        let splitMoveDate = this.modalData.moveDate.split('-');
        if (splitMoveDate && splitMoveDate.length === 2) {
          this.startDate = splitMoveDate[0].trim();
          this.endDate = splitMoveDate[1].trim();
        } else {
          this.startDate = this.modalData.moveDate;
        }
      }
    },
    close() {
      this.$modal.hide(this.modalName);
    },
    prevStep() {
      if (this.step > 1) {
        this.step--;
      } else {
        this.close();
      }
    },
    async nextStep() {
      console.log('STEP', this.step);
      if (this.step === 1 && this.modalData.allow_add_clause) {
        const valid = await this.$validate();
        if (valid) this.step++;
      } else {
        this.accept();
      }
    },
    validate() {
      let validationArray = [];
      for (let index = 0; index < this.$refs.clauseField.length; index++) {
        validationArray.push(this.$refs.clauseField[index].validate());
      }
      validationArray.push(this.$validate());
      return Promise.all(validationArray).then(function(results) {
        return (
          results.filter(function(result) {
            return !result;
          }).length === 0
        );
      });
    },
    async accept() {
      if (await this.validate()) {
        try {
          const result = await this.$store.dispatch(
            'v2/booking/acceptBookingRequest/postAcceptBooking',
          );
          if (result) {
            this.$emit('actionDone');
            this._close();
            this.$swal(
              this.$t('general.success'),
              this.$t('booking.swal.requestBookModal.acceptedMsg'),
              'success',
            );
          }
          // eslint-disable-next-line no-empty
        } catch (e) {}
      } else {
        this.scrollToErrorSection(this.$el);
      }
    },
    addClause() {
      this.$store.commit('v2/booking/acceptBookingRequest/ADD_CLAUSE');
      this.$nextTick(function() {
        this.$refs.clauseField[this.clauses.length - 1].$el.querySelector('textarea').focus();
      });
    },
    async openPreview() {
      if (await this.validate()) {
        const result = await this.$store.dispatch(
          'v2/booking/acceptBookingRequest/previewDigitalAgreement',
        );
        if (result && result.template) {
          this.step = 3;
          this.digitalSignPreview = result.template;
        }
      } else {
        this.scrollToErrorSection(this.$el);
      }
    },
    uploadAgreement() {
      console.log('asdasda');
      this.$refs.agreementDropzone.dropzone.hiddenFileInput.click();
    },
    removeAgreement() {
      this.customAgreement = {};
    },
    // eslint-disable-next-line no-unused-vars
    sending(file, xhr, formData) {
      xhr.setRequestHeader('Authorization', `Bearer ${this.$store.state.global.token}`);
    },
    success(file, response) {
      console.log('SUCCESS UPLOAD AGREEMENT!', file);
      if (response.type === 'success') {
        this.customAgreement = {
          file: file,
          uuid: response.data.uuid,
        };
      }
    },
  },
};
</script>

<style scoped></style>
